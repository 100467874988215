import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import enriched from "../../Assets/Projects/enriched.svg";
import suicide from "../../Assets/Projects/suicide.png";
import referral from "../../Assets/Projects/referral.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={enriched}
              isBlog={false}
              title="Enriched Academy"
              description="Enriched Academy is the first of its kind educational program specifically designed to teach 
              Canadians how to take control over their fi- 
              nances, build wealth and achieve financial succes.Enriched Academy is the first of its kind educational program specifically designed to teach Canadians how to take control over their fi- nances, build wealth and achieve financial succes.
              Skills: Angular Material · Angular CLI · Team Leadership · Node.js · Angular · JavaScript · MySQL · Express.js · GitHub · PHP"
              // ghLink="https://github.com/Devnarayan4419/Chatify"
              demoLink="https://new.enrichedacademy.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={referral}
              isBlog={false}
              title="Refferal"
              description="Introducing a user-centric real estate platform designed to simplify the process of property transactions. This platform empowers ordinary users to effortlessly connect with experienced property agents, facilitating seamless communication and deal negotiation. Whether buying, selling, or renting, our platform ensures a convenient and accessible avenue for users to navigate the complexities of real estate transactions with the support of knowledgeable agents.
              Skills: PayPal · Angular Material · Angular CLI · Team Leadership · Node.js · Angular · JavaScript · MySQL · Express.js · TypeScript · GitHub · Linux"
              // ghLink="https://github.com/Devnarayan4419/Bits-0f-C0de"
              demoLink="https://www.myreferralnetwork.ca/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Interpreter"
              description="Interpreter is a platform for interpreting languages task.A Services request is created by client and broadcast to multiple interpreters.This request can be done Onsite and offsite.After completing the request customers will give feedback with digitally signed.
              Skills: Angular Material · Angular CLI · Node.js · Angular · JavaScript · MySQL · Express.js"
              // ghLink="https://github.com/Devnarayan4419/Editor.io"
              // demoLink="https://editor.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="KellyArmy"
              description="Associated with Samosys Technologies Pvt. Ltd.
              A Cleanzy like cleaning services portal created 
              in Angular in frontend , api in nodejs and mysql 
              as database. 
              User can book there cleaning service request 
              and request is accepted by KellyArmy cleaners 
              and completed by them.A Cleanzy like cleaning services portal created in Angular in frontend , api in nodejs and mysql as database. User can book there cleaning service request and request is accepted by KellyArmy cleaners and completed by them.
              Skills: PayPal · Stripe · Angular CLI · Node.js · Angular · JavaScript · MySQL · TypeScript · API Development"
              // ghLink="https://github.com/Devnarayan4419/Plant_AI"
              // demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Virtual Tour"
              description="Associated with Samosys Technologies Pvt. Ltd.
              A video calling-based online platform for showrooms.
              Along with video calls, sellers can show 360* cars views to buyers.
              Use Socket for image sync from web to a mobile app (Android + IOS) in
              between the call.
              Use WebRTC for video calling from the web to Android and IOS.A video calling-based online platform for showrooms. Along with video calls, sellers can show 360* cars views to buyers. Use Socket for image sync from web to a mobile app (Android + IOS) in between the call. Use WebRTC for video calling from the web to Android and IOS.
              Skills: Node.js · JavaScript · Express.js · GitHub · WebRTC · Enablex · Socket.io"
              // ghLink="https://github.com/Devnarayan4419/AI_For_Social_Good"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Coolbates"
              description="
              Collbates is your go-to shopping platform, merging convenience and rewards. Offering a diverse product selection sourced directly from Amazon, it ensures a seamless shopping experience with the added perk of cashback. Elevate your online shopping journey with Collbates – where choices, savings, and convenience converge.
              Skills: Node.js · React.js · JavaScript · Express.js · GitHub  · Socket.io"
              // ghLink="https://github.com/Devnarayan4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="StarBooster"
              description="
              A Platform for Music Band , where members of music band can add album and many more.
              Skills: Node.js · React.js · JavaScript · Express.js · GitHub  . MYSQL"
              // ghLink="https://github.com/Devnarayan4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Career Qualifyed"
              description="
              A linkedin like job portal created using Angular in frontend, Nodejs in backend and mysql as database.
              Skills: Node.js · Angular 6 · JavaScript · Express.js · GitHub  . MYSQL"
              // ghLink="https://github.com/Devnarayan4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="File Saver"
              description="Introducing a robust cloud storage portal crafted with React.js in the frontend, Node.js in the backend, and MySQL as the database backbone. This Google Drive-like platform offers users a secure and efficient environment for storing, managing, and sharing their files seamlessly. With React.js's intuitive user interface, Node.js's powerful backend capabilities, and the reliability of MySQL, this portal ensures a comprehensive and user-friendly experience for effective file organization and collaboration.
              Skills: Node.js · React.js · JavaScript · Redux . Test cases · Express.js · GitHub  . MYSQL"
              // ghLink="https://github.com/Devnarayan4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
