import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiPostman,
  SiSkype,
  SiGooglemeet,
  SiUbuntu,SiWindows11,SiFilezilla,SiWindowsterminal,SiSublimetext
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiUbuntu />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiWindows11 />
      </Col>
      
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostman />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSkype />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSkype />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGooglemeet />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFilezilla />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiWindowsterminal />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiSublimetext />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiWindowsterminal />
      </Col>

      
    </Row>
  );
}

export default Toolstack;
